import React, { useState } from 'react';

import {
  NavLink,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import { selectAcquisitionOrderByCampaign } from '../../selectors/acquisitionOrder';
import { useSelector } from 'react-redux';

import Modal from '../../components/Common/Modal/Modal';
import FormLead from '../../components/Leads/FormLead';
import FormSurvey from '../../components/Surveys/FormSurvey';
import ShowNote from '../../components/AcquisitionOrders/ShowNote';
import DataEntryLinkTable from '../../components/MonetizationOrders/DataEntryLinkTable';
import FiscalCodeAlert from '../Common/FiscalCodeAlert';
import DealerFinder from '../Common/DealerFinder';

import classnames from 'classnames';
import { linkify } from '../../helpers/sharedFunction';
import { useTranslation } from 'react-i18next';
import { DEALER_BRANDS } from '../../config/settings';

const ManageLead = ({ lead, survey, loading }) => {
  const [activeTab, setActiveTab] = useState('1');
  const [toolsDropdownOpen, setToolsDropdownOpen] = useState(false);
  const [fiscalCodeAlert, setFiscalCodeAlert] = useState(false);
  const [DealerModal, setDealerModal] = useState(false);

  const [formIsValid, setFormIsValid] = useState('');

  const acquisitionOrder = useSelector(
    selectAcquisitionOrderByCampaign(lead ? lead.campaignId : null),
  );

  let formLeadStatus = null;

  const handleSubmitLeadForm = async () => {
    if (!formLeadStatus) return;

    const { submitForm, resetForm, values, isValid } = formLeadStatus;
    setFormIsValid(isValid);
    if (isValid) {
      submitForm();
      resetForm({ values });
    }
  };

  const toolsDropdownToggle = () => setToolsDropdownOpen(!toolsDropdownOpen);

  const toggleTab = (tab) => activeTab !== tab && setActiveTab(tab);

  const { t } = useTranslation();

  return (
    <>
      <div className="d-flex">
        <Nav pills className="flex-grow-1">
          <NavItem>
            <NavLink
              style={{ cursor: 'pointer' }}
              className={classnames({
                active: activeTab === '1',
              })}
              onClick={() => toggleTab('1')}>
              <span className="d-none d-sm-block">Lead</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: 'pointer' }}
              className={classnames({
                active: activeTab === '2',
              })}
              onClick={() => toggleTab('2')}>
              <span className="d-none d-sm-block">{t('Survey')}</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: 'pointer' }}
              className={classnames({
                active: activeTab === '3',
              })}
              onClick={() => toggleTab('3')}>
              <span className="d-none d-sm-block">Link</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: 'pointer' }}
              className={classnames({
                active: activeTab === '4',
              })}
              onClick={() => toggleTab('4')}>
              <span className="d-none d-sm-block">Info</span>
            </NavLink>
          </NavItem>
          {lead.note && (
            <NavItem>
              <NavLink
                style={{ cursor: 'pointer' }}
                className={classnames({
                  active: activeTab === '5',
                })}
                onClick={() => toggleTab('5')}>
                <span className="d-none d-sm-block">Note</span>
              </NavLink>
            </NavItem>
          )}
          <Dropdown nav isOpen={toolsDropdownOpen} toggle={toolsDropdownToggle}>
            <DropdownToggle nav caret custom-ignore={'true'}>
              {t('Tools') + ' '}
              <i className="mdi mdi-chevron-down"></i>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => setFiscalCodeAlert(true)}>
                {t('Fiscal Code')}
              </DropdownItem>
              {typeof lead?.carBrand === 'string' &&
                DEALER_BRANDS.includes(lead?.carBrand.toLowerCase()) && (
                  <DropdownItem onClick={() => setDealerModal(true)}>
                    {t('Dealer Finder')}
                  </DropdownItem>
                )}
            </DropdownMenu>
          </Dropdown>
        </Nav>
        {activeTab === '1' && (
          <Nav className="navbar-right">
            {formIsValid !== '' && (
              <NavItem className="font-size-24 mr-2">
                <i
                  className={`bx ${
                    formIsValid
                      ? 'bx-check-circle text-success'
                      : 'bx-error text-danger'
                  }`}></i>
              </NavItem>
            )}
            <NavItem>
              <button
                type="button"
                disabled={loading}
                className="btn btn-dark waves-effect waves-light"
                onClick={handleSubmitLeadForm}>
                <i className="bx bx-pencil font-size-16 align-middle mr-2"></i>
                {t('Save')}
              </button>
            </NavItem>
          </Nav>
        )}
      </div>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <FormLead
            create={!lead}
            loading={loading}
            lead={lead}
            disabledSelectCampaign
            hideCampaignField
            hideClosureFields
            hideSubmitButton
            manageDirtyForm
            bindSubmitForm={(formStatus) => {
              formLeadStatus = formStatus;
            }}
          />
        </TabPane>
        <TabPane tabId="2">
          {survey ? (
            <FormSurvey create={!survey} survey={survey} loading={loading} />
          ) : (
            <Card>
              <CardBody>
                <h5 className="mt-4 ml-4">
                  {t('This user did not answer the survey')}.
                </h5>
              </CardBody>
            </Card>
          )}
        </TabPane>
        <TabPane tabId="3">
          <Card>
            <CardBody>
              <DataEntryLinkTable
                lead={lead}
                acquisitionOrder={acquisitionOrder}
              />
            </CardBody>
          </Card>
        </TabPane>
        <TabPane tabId="4">
          {acquisitionOrder && acquisitionOrder.note ? (
            <ShowNote acquisitionOrder={acquisitionOrder} />
          ) : (
            <Card>
              <CardBody>
                <h5 className="mt-4 ml-4">
                  {t('There is no information about this acquisition order')}.
                </h5>
              </CardBody>
            </Card>
          )}
        </TabPane>
        {lead.note && (
          <TabPane tabId="5">
            <Card>
              <CardBody>
                <p
                  style={{
                    whiteSpace: 'pre-wrap',
                    lineHeight: '3',
                    fontSize: '14px',
                  }}
                  dangerouslySetInnerHTML={{ __html: linkify(lead.note) }}
                />
              </CardBody>
            </Card>
          </TabPane>
        )}
      </TabContent>
      <FiscalCodeAlert
        isOpen={fiscalCodeAlert}
        onConfirm={() => setFiscalCodeAlert(false)}
        lead={lead}
      />
      <Modal
        size="xl"
        title={`${lead?.carBrand} ${t('Dealer Finder')}`}
        isOpen={DealerModal}
        toggle={() => setDealerModal(!DealerModal)}
        unmountOnClose={false}
        showClose
        scrollable>
        <DealerFinder lead={lead} />
      </Modal>
    </>
  );
};

export default ManageLead;
